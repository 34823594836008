<template>
    <v-app>
      <v-card class="bgs rounded-0 d-flex justify-center" flat width="100%" height="100%">
        <v-container>
            <h2 class="text-center mt-6 font24 mb-4">
          {{ $t("Taoify 服务条款") }}
        </h2>
        
<p class="content">
  {{ $t("为了注册 Taoify 账号或使用 Taoify 的服务，您应当阅读并遵守 Taoify《服务条款》（以下简称“本条款”）。本条款中的术语“Taoify”和“我们”指代适用的 Taoify 缔约方（如下面第4节所定义）。") }}
</p>
<p class="content">
  {{ $t("Taoify 根据本条款提供多种产品和服务，这些产品和服务将帮助您自助搭建线上商店（以下简称“线上服务”），以便向顾客销售您的产品或服务。上述产品与服务在本条款中统称为“Taoify服务”。任何添加到 Taoify 服务中的新功能或工具都应该受到本条款的约束。我们可能会随时更新本条款里的内容，您可以在商城处查看最新版本的《服务条款》，Taoify 保留通过在 Taoify 网站上发布更新和更改来修改更新本条款的权利。建议您不定期检查本《服务条款》，以了解可能影响您的任何更新或更改。如果您拒绝接受对条款的更新或修改，请立即停止使用 Taoify 服务。") }}
</p>
<p class="content">
  {{ $t("在您注册 Taoify 账户或使用 Taoify 前，您必须阅读、同意并且接受本服务条款中包含的所有条款和条件，包括 《使用协议》、《隐私策略》、《API许可与使用条款》（以下简称API条款）。") }}
</p>

<h3 class="titlee">{{ $t("一.账户条款") }}</h3>
<ol class="content">
  <li>{{ $t("要访问和使用 Taoify 服务，您注册 Taoify 账号时必须提供合法的全名、当前地址、电话号码、有效的电子邮件地址以及其他所需的任何信息。Taoify 可出于任何理由拒绝您的账号申请或取消现有账户。") }}</li>
  <li>{{ $t("您必须年满18岁或至少达到在您居住或使用本服务所在地（“经营所在地”）的法定成年的年龄。") }}</li>
  <li>{{ $t("您确认是为经营之商业目的使用 Taoify 服务，而非为个人或家庭原因使用。") }}</li>
  <li>{{ $t("您声明并接受，您注册账户时所提供或随后更新的邮箱是 Taoify 与您沟通的主要途径。您应该注意查看该邮箱，并确保它可以收发邮件。您与 Taoify 的电子邮件通信只有在它们来自您的主要帐户电子邮件地址时才能视为来自于您本人。") }}</li>
  <li>{{ $t("您有责任确保您的账户密码安全。如果因为您自己的原因未能保证账号密码的安全而造成任何的损失或损害，Taoify 不承担任何责任。") }}</li>
  <li>{{ $t("您认可您对自己店铺的创建与运营负责。") }}</li>
  <li>{{ $t("Taoify 不是一个网络零售平台（marketplace），您通过 Taoify 技术生成的店铺产生的交易是您与第三方进行的直接交易，和 Taoify 无关。") }}</li>
  <li>{{ $t("您对您账户所有的活动和上传、收集、生成、存储、显示、分发、传输或展示的材料（包括照片、图像、视频、图形、书写内容、音频文件、代码、信息或数据）负责。") }}</li>
  <li>{{ $t("如果触犯或违反本服务条款中的任何条款（包括 《使用协议》）（该违约将由 Taoify 自行认定），您的服务或将立即终止。") }}</li>
</ol>
<h3 class="titlee">{{ $t("二.账户激活") }}</h3>
<ol class="content">
  <li>{{ $t("Taoify 账户") }}
    <ol>
      <li>{{ $t("商店所有者") }}
        <ol>
          <li>{{ $t("根据第1.1.2节的规定，为了本条款的目的，注册服务的人将是缔约方（“商店所有者”），并且将被授权使用我们提供给商店所有者的相关服务。您负责确保店主的姓名（包括该商店所属公司的法定名称，如适用）清晰显示在商店的网站中。") }}</li>
          <li>{{ $t("如果您代表您的雇主注册服务，则您的雇主应为商店所有者。如果您代表您的雇主注册服务，您必须使用您雇主提供的邮箱地址，且您声明并保证您已经取得授权以使您的雇主受此《服务条款》的约束。") }}</li>
          <li>{{ $t("您的 Taoify 商店只能关联到一名商店所有者。一名商店所有者可以有多家 Taoify 商店。“商店” 表示与账户相关联的在线商店或者实体零售地点。") }}</li>
        </ol>
      </li>
      <li>{{ $t("员工帐户") }}
        <ol>
          <li>{{ $t("根据您订阅的 Taoify 套餐，您可以创建一个或多个员工帐户（“员工帐户”），以允许其他人访问该帐户。 商店所有者可以设置权限并确定员工账户对特定业务信息的访问级别（例如，您可以将员工账户设定为限制访问“报表”页面上的销售信息，或者禁止员工账户更改一般商店设置）。") }}</li>
          <li>{{ $t("对于因履行本服务条款所规定的义务而使用员工帐户而产生的一切后果均为商店所有者负责，并应将其视为由商店所有者自身的作为。") }}</li>
          <li>{{ $t("商店所有者和“员工帐户”下的用户均称为“Taoify用户”。") }}</li>
        </ol>
      </li>
    </ol>
  </li>
  <li>{{ $t("Checkout账户") }}
    <ol>
      <li>{{ $t("在完成服务的注册后，Taoify 将提供多家支付服务商供商户进行选择。您可以从中选择收取顾客款项的渠道。") }}</li>
      <li>{{ $t("您须自行负责注册、激活及维护收款账户。如果您不再需要这些收款账户保持激活状态，您须自行关停账户。为了避免歧义，支付服务商属于第三方服务，在本条款的第15条有定义。") }}</li>
      <li>{{ $t("在您的商店中使用收款账户，即表示您同意受相应支付服务商的条款和条件的约束，这些支付服务商可能会不时对这些条款和条件进行修订。此类修订自发布之日起即生效。如果您在修订的条款发布后继续在您的商店中使用收款账户，即表示您同意并接受修订后的条款。 如果您不同意支付服务商对条款的任何更改，请关停您的收款账户并且不要继续在您的商店中使用相应的支付服务。") }}</li>
    </ol>
  </li>
</ol>
<h3 class="titlee">{{ $t("三.通用条款") }}</h3>
<p class="content">
  {{ $t("在您成为 Taoify 会员前，您必须阅读、同意并且接受本服务条款中包含的所有条款和条件，包括 《使用协议》和《隐私策略》。") }}
</p>
<ol class="content">
  <li>{{ $t("Taoify 服务相关的技术支持仅提供给 Taoify 用户。") }}</li>
  <li>{{ $t("服务条款适用安大略省法律及适用的加拿大法律的约束，并依其进行解释，且排除法律冲突规则。《联合国国际货物销售合同公约》不应用于本服务条款，因此被明确排除在外。") }}</li>
  <li>{{ $t("您认可并同意 Taoify 可以通过在商城发布相关的修正或重述的服务条款来修改本条款。本条款的修订版在文首所属更新日的七（7）日公示期后的生效日生效。如果您在公示期间没有提出异议或在生效日后继续使用我们的服务即表示您同意并接受此修订版《服务条款》，如果您不同意本服务条款的任何更改，请不要继续使用我们的服务。") }}</li>
  <li>{{ $t("您不得将 Taoify 服务用于任何非法或未经授权的目的，也不得在使用本服务时违反您经营所在地的任何法律（包括但不限于版权法）、适用于您客户所在地的法律法规或者中国的任何法律法规。您将在使用本服务时遵守所有适用的法律、法规和规章制度以及本服务条款。") }}</li>
  <li>{{ $t("您对 Taoify API（同API条款中的定义）的访问和使用受《API许可与使用条款》的约束。您需要自行对使用您的API证书（同API条款中的定义）的行为负责，并需自行确保API证书的安全。") }}</li>
  <li>{{ $t("未经 Taoify 书面许可，您不得对服务的任何部分、服务的使用或服务的访问进行再生产、复制、销售、转售或以此牟利。") }}</li>
  <li>{{ $t("您不得购买使用 Taoify 、Taoify 商标以及其变体或错误拼写的域名和搜索引擎或其他点击付费关键词（例如谷歌广告）。") }}</li>
  <li>{{ $t("有关本条款的任何问题，请发邮件到 。") }}</li>
  <li>{{ $t("您知晓您的资料（不包括信用卡信息）可能未经加密转移，并涉及（a）通过各种网络传输；（b）为符合和适应连接网络或设备技术要求作出的改变。信用卡信息在网络传输过程中始终是加密的。") }}</li>
  <li>{{ $t("你认可并同意，您对 Taoify 服务的使用，包括传送给 Taoify 或者由 Taoify 存储的信息，均受《隐私策略》的保护和约束。") }}</li>
  <li>{{ $t("本条款可能会有除中文以外的其他版本。如果不同语言版本的服务条款之间存在任何矛盾或冲突，以最新的英文版《服务条款》为准。除非 Taoify 另有决定（Taoify 有权自行决定）或适用法律要求，由本条款引起的争议将全部通过中文解决。") }}</li>
  <li>{{ $t("《服务条款》的所有条款和规定对《服务条款》双方及其各自的继承人、继任者、获准受让人和法定代表人具有约束力，并对其有利。 Taoify 有权在不通知您或未经您同意的情况下转让这些服务条款。未经 Taoify 事先书面同意，您无权将本服务条款或您的本协议下的任何权利或义务转让或以其他方式转让给第三方。") }}</li>
  <li>{{ $t("如出于任何原因导致本《服务条款》中的任何条款或条款的一部分是无效或不可执行的，则此条款或部分无效或不可执行，但将不会影响任何其他条款。本《服务条款》将被视为未涵盖此无效或不可执行的条款。") }}</li>
  <li>{{ $t("本条款的第1部分、第3部分的2-5条，第4部分，第6至第8部分，第12-13节，第15节第6条至第8条，第17节、第19节和20节将在本条款终止或过期后继续有效。") }}</li>
</ol>

<h3 class="titlee">{{ $t("四.账单地址") }}</h3>
<ol class="content">
  <li>{{ $t("如果您商店的账单地址位于美国、加拿大或欧盟（欧洲联盟成员国），则本4.1节适用于您：") }}
    <ol>
      <li>{{ $t("对于因服务条款而产生的或与服务条款有关的任何争议或索赔，双方应不可撤销地且无条件地服从安大略省法院的专属管辖。《联合国国际货物销售合同公约》不适用于本服务条款，因此明确排除在外。") }}</li>
    </ol>
  </li>
  <li>{{ $t("如果您商店的账单地址位于亚太地区（包括澳大利亚、新西兰、中国、日本、新加坡），中东及非洲地区或第4.1节所约定地区以外的任何区域，包括俄罗斯、南美洲，加勒比海或墨西哥，则本4.2节适用于您：") }}
    <ol>
      <li>{{ $t("对于因服务条款而产生的或与服务条款有关的任何争议或索赔，双方应不可撤销地且无条件地服从香港法院的专属管辖。《联合国国际货物销售合同公约》不适用于本服务条款，因此明确排除在外。") }}</li>
    </ol>
  </li>
</ol>

<h3 class="titlee">{{ $t("五.Taoify 权利") }}</h3>
<ol class="content">
  <li>{{ $t("我们保留随时以任何理由修改或终止本服务的权利，且不另行通知。并非所有服务和功能在各个司法管辖区都可用，我们没有义务在任何司法管辖区提供任何服务或功能。") }}</li>
  <li>{{ $t("我们保留随时拒绝向任何人提供服务的权利。") }}</li>
  <li>{{ $t("如果我们判定店铺提供的商品或服务或者发布到店铺的资料违反了 Taoify 的《使用协议》或本服务条款，我们有权，但无义务，移除资料并暂停或终止其账户。") }}</li>
  <li>{{ $t("对 Taoify 客户、Taoify 员工、成员或职员进行任何形式的口头或书面辱骂（包括辱骂或报复威胁）将导致账户被立即终止。") }}</li>
  <li>{{ $t("Taoify 不会预先筛选资料，我们可以自行决定拒绝或删除服务中的任何资料。") }}</li>
  <li>{{ $t("我们保留向您竞争对手提供服务的权利，并且不承诺在任何特定的细分市场有排他性。您进一步认可并且同意 Taoify 合作商或雇员也可能是 Taoify 的客户或商家，他们可能会与您竞争，但他们无法使用您的机密信息。") }}</li>
  <li>{{ $t("如果账户所有权存在争议，我们保留要求提供账户所有权证明文件的权利。文件可能包括但不限于您的营业执照的扫描件，政府注册签发的身份证件，信用卡后四位数字、雇佣证明等。") }}</li>
  <li>{{ $t("我们有权判定合法账户的所有权，并有权根据我们的单方判断将账户转让给合法的账户所有者。如果我们无法判定账户合法所有者，在不影响 Taoify 可能的其他权利与救济的情况下，Taoify 将保留暂时禁用此账户的权利，直至争端双方确定解决方案为止。") }}</li>
</ol>
<h3 class="titlee">{{ $t("六.保密") }}</h3>
<ol class="content">
  <li>{{ $t("“保密信息”应包括但不限于与当事方业务相关且未公开的任何和所有信息，包括特定的业务信息、技术流程和公式、软件、客户列表、潜在客户列表、名称 、有关客户和潜在客户的地址和其他信息、产品设计、销售额、成本（包括任何相关处理费）、价格表以及其他未发布的财务信息、商业计划和营销数据、以及任何其他机密和专有信息，无论是否标记为保密或专有信息。Taoify 的机密信息包括您收到的尚未被公众所知的与我们或服务相关的所有信息，包括与我们的安全计划或实践的有关信息。") }}</li>
  <li>{{ $t("双方同意仅在履行本服务条款所规定的义务范围内，并根据本服务条款中约定的其他义务，使用对方的保密信息。各方同意，其应采取一切合理的措施步骤，至少是等同于一方用于保护自身保密信息的措施，防止复制、披露或使用任何此类保密信息，并且（i）其必须访问此类信息的其员工、代理商和分包商及其员工，均应遵守此保密义务；（ii）在法律允许的情况下，在依据相关法律、法院命令披露此类信息时，接收方应及时向披露方发出书面通知并尽商业上合理的努力，以确保此类披露得到保密处理。保密信息不应包括接收方可以证明的此类信息：（A）在披露此类信息时，该信息已经在公共领域，或者已经被接收方知道或拥有； （B）由接收方独立开发，且并未使用或参考另一方的保密信息，也未违反本服务条款的任何规定；或（C）之后由接收方从披露方以外的其他来源正确获得，且该来源并未违反这些服务条款的任何规定。") }}</li>
</ol>

<h3 class="titlee">{{ $t("七.责任限制") }}</h3>
<ol class="content">
  <li>{{ $t("您明确理解并同意，在法律允许的范围内，Taoify 不承担任何因为使用或无法使用 Taoify 服务导致的直接、间接、偶然的、特殊的、从属性的或惩罚性的损失，包括但不限于因使用或无法使用该服务带来的收益、商誉、数据或其他无形的损失。") }}</li>
  <li>{{ $t("在法律允许的范围内，在任何情况下，Taoify 及其供应商不承担由我们的网站、服务或本《服务条款》（包括因为过失引起的）引起的或与之相关的利润损失或任何特殊的、偶然的或附属性的损失。您应确保 Taoify 公司及其关联方、Taoify 的合作伙伴、Taoify 的高级职员、董事、代理人、员工和供应商免于因为您违反本《服务条款》或其他附属文件（包括《使用协议》），或您违反任何法律或第三方的权利而被起诉带来的赔偿要求，并同意赔偿我们已支付的相关诉讼费和损害赔偿，包括合理的律师费。") }}</li>
  <li>{{ $t("您使用本服务需要自担风险。服务以“现状”和“现有”的基础提供给您，不含任何明示、暗示或法定的保证或条件。") }}</li>
  <li>{{ $t("Taoify 不保证本服务及时、安全、无错、不会中断。") }}</li>
  <li>{{ $t("Taoify 不保证通过使用本服务获得的结果是准确的或可靠的。") }}</li>
  <li>{{ $t("Taoify 不为您因使用 Taoify 服务而产生的任何税务和费用缴纳义务承担责任。") }}</li>
  <li>{{ $t("Taoify 不保证您通过“服务”购买或获取的任何产品，服务，信息或其他材料的质量都将达到您的期望，或者“服务”中的任何错误都将得到纠正。") }}</li>
</ol>

<h3 class="titlee">{{ $t("八.弃权、可分割性和完整协议") }}</h3>
<p class="content">
  {{ $t("Taoify 未行使或执行本条款里的任何权利或条款并不构成对此权利或条款的放弃。如果本条款有一项或多项规定（包括所有条款和以参考形式纳入其中的其他文件）被具有合法管辖权的法院裁定为无效、不合法或不可强制执行，则 Taoify 将更改和解释此类规定以在法律许可的最大限度内取代该等无效、不合法或不可强制执行的规定，而该等有效的规定所产生的经济效果应尽可能与该些无效、不合法或不可强制执行的规定所产生的经济效果相似，且本条款其余条款的有效性、合法性或可强制执行性不应在任何方面受到影响或损害。") }}
</p>
<p class="content">
  {{ $t("本服务条款（包括以参考形式纳入其中的其他文件）构成您与 Taoify 之间的完整协议，约束您对服务和账户的使用，同时可取代您与 Taoify 之间的任何先前协议（包括但不限于任何以前版本的服务条款）。") }}
</p>

<h3 class="titlee">{{ $t("九.知识产权和客户内容") }}</h3>
<ol class="content">
  <li>{{ $t("我们不会对您向 Taoify 服务提供的材料主张任何知识产权，您保留您提供给 Taoify 服务的所有资料的知识产权。您上传的所有资料都归您所有。您可以随时删除您的账户以删除您的 Taoify 店铺。") }}</li>
  <li>{{ $t("在上传资料时，您同意：（a）允许其他互联网用户查看您公开发布到您店铺的资料；（b）允许 Taoify 存储、展示和使用您的资料（对于您公开发布的资料）；（c）Taoify 可以在任何时候检查和删除您提交给其服务的任何资料，但 Taoify 并无此义务。") }}</li>
  <li>{{ $t("您保留您上传到 Taoify 店铺的所有资料的所有权；但是，为了让您的店铺公开，您同意并允许其他人查看您公开发布到店铺的资料。您有责任保证这些资料遵守任何适用的法律或法规。") }}</li>
  <li>{{ $t("Taoify 拥有使用与您店铺相关的名称、注册商标、服务标志和品牌徽标（logo）去营销 Taoify 服务的非排他性的权利和许可。") }}</li>
</ol>

<h3 class="titlee">{{ $t("十.发货") }}</h3>
<p class="content">
  {{ $t("对于您 Taoify 商铺所售出的商品，您可以在发货时可以从 Taoify 的第三方承运商或自行联系第三方承运商提供商处购买运输标签。") }}
</p>

<h3 class="titlee">{{ $t("十一.主题商店") }}</h3>
<ol class="content">
  <li>{{ $t("您可以使用 Taoify 主题装修（“主题模版”）中的设计模板来设计 Taoify 商店的外观。您可以选择修改主题模版以使之适合您的店铺。Taoify 可以酌情添加或修改用于引用 Taoify 的页脚。Taoify 也可以自行决定修改其中违反 Taoify《使用协议》或《服务条款》的内容，即使您已收到该类模板。Taoify 可以根据需要修改主题模版以反映技术更改和更新。") }}</li>
  <li>{{ $t("主题模版的知识产权仍属于设计者。如果您超出了购买主题允许的权利，设计者可能会对您采取法律行动，在不影响其他权利及补救措施的前提下，Taoify 可能会采取管理措施，如修改您的店铺或者关闭您的店铺。") }}</li>
  <li>{{ $t("主题模版的技术支持工作由其设计者负责，Taoify 不承担提供此类支持的责任，但 Taoify 可帮助您联系设计者。") }}</li>
  <li>{{ $t("确保安装的新模板不会覆盖或者破坏用户当前或预存的主题或用户界面是用户的责任，Taoify 不承担任何的损失或损害。") }}</li>
</ol>

<h3 class="titlee">{{ $t("十二.费用支付") }}</h3>
<ol class="content">
  <li>{{ $t("您必须向我们提供有效可用的支付方式，以避免扣款失败影响店铺使用。建议您在「后台设置」>「账单」>「套餐与支付」>「支付方式管理」中绑定多张信用卡并授权使用于账单支付。账单支付的技术由第三方专业金融公司集成并合作，Taoify 平台不保存任何敏感的支付信息，请放心使用。") }}</li>
  <li>{{ $t("账单出账后系统将按照您在授权的支付方式(“授权卡”)自动发起扣款，直到服务终止。若您设置了默认卡，系统优先使用默认卡发起扣款。除非另有说明，所有费用和其他收费均以美元计，所有付款均以美元计价。") }}</li>
  <li>{{ $t("费用将显示在账单上，并通过提供的电子邮件发送给账户所有者。如果您对账单存在疑问，可以与 Taoify 的相关业务部门进行联系。后续将通过电子邮件的形式与您取得联系，账单争议内容将发送至您所提供的店铺财务邮箱，您可以在后台「店铺设置」>「系统设置」>「基础设置」>「店铺信息」中进行配置或修改。") }}</li>
  <li>{{ $t("如果您有开票需求，可以与 Taoify 的相关业务部门进行联系。发票将通过以电子邮件发送给至您所提供的店铺财务邮箱。") }}</li>
  <li>{{ $t("订阅费是提前支付的，在您已订阅服务到期时间的基础上提前5个自然日，如服务到期时间是2022-01-06 23:59:59，则您订阅服务的预计续费时间为2022-01-01 23:59:59。") }}</li>
  <li>{{ $t("如果我们无法向授权卡收取订阅费，我们将向账户所有者发送一封电子邮件，告知付款失败并提醒更新信用卡。我们每隔24小时将再次尝试向授权卡收费，最多进行三次收费尝试（含首次收费尝试）。在第三次收费失败后，我们可暂停和撤销对您账户的访问权限。在您支付全部未结费用以及下一个账单周期的使用费用后，您的账户将重新激活，恢复访问权限。") }}</li>
  <li>{{ $t("如果自暂停之日起60天内仍未支付未结清费用，Taoify 保留终止您帐户的权利。") }}</li>
  <li>{{ $t("所有费用均不包括政府征收的相关税费、费用或收费（以下简称“税费”）。您须负责支付因订阅或购买 Taoify 产品和服务而产生的所有适用税费。在 Taoify 的税费收取范围内，税费根据您向我们提供的账单地址的适用税率计算。此类费用是此类产品和服务的费用之外的额外费用，将向您的授权卡收取。如果您可以免缴此类税金，您必须向我们提供您的免税证明，在某些管辖区内，免税证明包括可证明您符合免税资格且符合适用法律要求的原始证书。免税仅在 Taoify 收到符合条件的免税证据之日起适用。如果 Taoify 未向您收取税费，则应由您负责确定是否应缴纳税费，如果应缴纳税费，应自行向您所在管辖区内的相应税务机关缴税。") }}</li>
  <li>{{ $t("除了 Taoify 向您收取并代您汇给相应税务机关的税款外，法律要求的任何扣除或预扣均应由您承担并单独支付给相关税务机关。Taoify 有权向您的授权卡收取本服务条款规定的全部费用，而不考虑可能需要的任何此类扣除或预扣。") }}</li>
  <li>{{ $t("您应自行负责缴纳因您的 Taoify 商店的所有销售而产生的所有适用税费。") }}</li>
  <li>{{ $t("Taoify 不提供退款。") }}</li>
</ol>

<h3 class="titlee">{{ $t("十三.取消和终止") }}</h3>
<ol class="content">
  <li>{{ $t("您可以通过随时发送电子邮件到 取消您的账户并终止服务条款，然后按照 Taoify 在回复邮件中的具体说明进行操作。") }}</li>
  <li>{{ $t("任何一方由于任何原因终止服务：•Taoify 将停止向您提供服务，您将不能再访问您的账户；•除非服务条款另有规定，否则您无权按比例或以其他方式获得任何费用的退款；•在终止生效日期之前，您因使用 Taoify 服务而拖欠 Taoify 的任何未付账款将立即到期并需全额支付；•您的 Taoify 店铺将被关闭下线。") }}</li>
  <li>{{ $t("服务终止后，您需要与域名供应商处理有关域名的全部事宜。") }}</li>
  <li>{{ $t("服务终止之日，如果您有任何未结费用，您将通过电子邮件收到一份最终账单。在该账单全额付清后，我们不会再向您收取费用。") }}</li>
  <li>{{ $t("我们保留根据法律法规变化优化服务等需要，不时修改、补充服务条款、使用协议及隐私政策等 Taoify 条款、修改或终止 Taoify 服务和/或您的账户的权利，终止不影响终止日期之前产生的任何权利或义务。变更后的条款将通过在平台公告的方式公示。如您不同意更新后的条款，您有权于公告确定的生效日前联系我们反馈意见。如您对已生效的更新条款仍不同意的，您应当于公告确定的生效之日起停止使用我们的服务，更新后的协议对您不产生效力，如您在更新协议生效后仍继续使用我们的服务，则视为您同意已生效的协议。") }}</li>
  <li>{{ $t("欺诈：如果我们怀疑您涉嫌任何欺诈行为（通过判决、和解、保险、托管调查或其他方式），Taoify 可暂停或终止您的账户，且上述行为不影响 Taoify 采取任何其他补救措施。") }}</li>
</ol>

<h3 class="titlee">{{ $t("十四.服务和服务费修改") }}</h3>
<ol class="content">
  <li>{{ $t("Taoify 在提供30日通知期后可修改服务费。Taoify 可随时以公告形式将更改发布到 Taoify 网站或您 Taoify 商店的管理菜单来提供此类通知。") }}</li>
  <li>{{ $t("Taoify 保留不经通知（除非所适用的法律另有规定）不时修改或终止服务（或其任何部分）的权利。") }}</li>
  <li>{{ $t("Taoify 不会就关于服务的任何修改、价格变动、暂停或终止对您或任何第三方承担任何责任。") }}</li>
</ol>

<h3 class="titlee">{{ $t("十五.第三方服务") }}</h3>
<ol class="content">
  <li>{{ $t("Taoify 可时常向您推荐和提供第三方软件、应用程序（以下简称“应用”）、产品、服务的接口或网站链接（统称为“第三方服务”）的访问权限或启用这些服务（包括通过 Taoify 应用商店或专家市场提供的服务），供您考虑或使用。此类第三方服务只是为了提供方便，您购买、访问或使用任何此类第三方服务将是您与相应第三方服务提供商（以下简称“第三方提供商”）之间的往来。 除了本服务条款之外，您还同意遵守您从第三方提供商处购买或由第三方提供商提供的服务所适用的特定服务附加条款。") }}</li>
  <li>{{ $t("对于使用 Taoify 网站提供的第三方服务产生的风险，完全由您自行承担，您有责任在使用此类第三方服务前先阅读适用的条款和条件及/或隐私政策。在某些情况下，Taoify 可能会从 Taoify 向您推荐的第三方服务或您通过 Taoify 网站选择使用的其他第三方服务的服务提供商处获得收益份额。") }}</li>
  <li>{{ $t("我们不提供有关第三方服务的任何保证或陈述。您认可 Taoify 无法控制第三方服务，因此对使用此类第三方服务的任何人不承担任何责任。在 Taoify 网站（包括 Taoify 应用商店或专家市场）上提供第三方服务、将此类第三方服务与 Taoify 服务集成或启用此类第三方服务均不构成或暗示 Taoify 对该服务的认可、授权、推荐或与其有从属关系。Taoify 不保证第三方服务的可用性，您认可Taoify可能随时自行决定禁止访问任何第三方服务，且无需通知您。Taoify 不会因为暂停访问或禁用某第三方服务对任何人承担任何责任。Taoify 强烈建议您在使用第三方服务前先寻求专家建议，以确保它们满足您的需求。尤其是在评估您应向客户收取的正确税率时，税费计算器应仅供参考，不得替代独立税务建议。") }}</li>
  <li>{{ $t("如果您在使用本服务时安装或启用了第三方服务并将其与 Taoify 服务配合使用，则意味着您授权我们允许适用的第三方提供商访问您的数据和其他材料、并采取必要措施确保第三方服务和 Taoify 服务之间的交互操作。任何您与第三方提供商之间的数据交换和其他交互仅限于您与此类第三方供应商之间。Taoify 不对您的数据或其他材料的任何披露、修改或删除承担任何责任，也不对您因第三方服务或第三方提供商可访问您的数据或其他材料而遭受的任何相应损失或损害承担任何责任。谷歌地图是 Taoify 服务中使用的第三方地图服务。如果您要使用 Taoify 服务，您必须接受谷歌地图和谷歌地球的企业通用隐私策略和服务协议，以及上述条款的不时更新，您需要不定期去查看其条款的不时更新。") }}</li>
  <li>{{ $t("您知悉并同意：(i) 通过 提交协助请求或针对其他信息的请求，即表示您同意一家或多家第三方提供商可通过商店所有者的注册邮箱（或您提供的此类其他邮箱）以及适用的用户邮箱与您联系；(ii) Taoify 将通过专家市场或在从专家市场（直接或间接）发出的任何答复电子邮件（每次“答复”）中收到您与专家之间交换的所有电子邮件。此外，您还同意 Taoify 有权向第三方服务商提供和共享您通过 提交的联系方式和背景信息。第三方可能需要访问您 Taoify 商店的某些后台页面。您可以选择允许第三方访问的页面。") }}</li>
  <li>{{ $t("您与任何第三方服务商之间的关系严格限定在您与第三方服务商之间，Taoify 没有义务介入您与第三方服务商之间发生的任何争议。") }}</li>
  <li>{{ $t("在任何情况下，Taoify 均不对由于任何第三方服务或您与第三方提供商之间的合作关系所造成的任何直接、间接、偶然、特殊、惩罚性、或其他损害负责。即使已向 Taoify 告知可能产生此类损害赔偿，这些责任限制依然适用。上述限制应在适用法律允许的最大范围内适用。") }}</li>
  <li>{{ $t("如果您在使用第三方服务时与第三方提供商发生了利益纠纷，您保证 Taoify 公司及其关联方、Taoify 的合作伙伴、Taoify 的高级职员、董事、代理人、员工和供应商免于任何第三方提出的起诉和损害赔偿要求，并同意赔偿我们已支付的相关诉讼费和损害赔偿，包括合理的律师费。") }}</li>
</ol>
<h3 class="titlee">{{ $t("十六.Beta版服务") }}</h3>
<p class="content">
  {{ $t("Taoify 可自行决定时常邀请您试用仍处于开发阶段且尚未向所有商家提供的预发布功能或beta版功能（以下简称“Beta 版服务”）。要使用 Beta 版服务，您应遵守附加条款和条件，Taoify 将在您使用 Beta 版服务之前向您提供这些条款和条件。此类Beta版服务以及与之相关的所有对话和材料均将被视为 Taoify 的机密信息，应遵守本协议中的保密规定。在不影响前述规定的通用性的前提下，您同意在未经 Taoify 事先书面同意的情况下，您不会发表任何公开声明或以其他方式披露您参与试用 Beta 版服务的情况。Taoify 对 Beta 版服务的功能不做任何陈述或保证。Taoify 可随时自行决定中止 Beta 版服务。 对于因 Beta 版服务引起或与之相关的任何伤害或损害，Taoify 不承担任何责任。Beta版服务的工作方式可能与最终版本不同。Taoify 可自行决定更改或不发布 Beta 版服务的最终版本或商用版本。") }}
</p>

<h3 class="titlee">{{ $t("十七.意见反馈") }}</h3>
<p class="content">
  {{ $t("Taoify欢迎任何有关改进或增补服务的想法或建议。在任何情况下，您向 Taoify 披露的任何想法、建议或相关材料，对 Taoify 服务、第三方服务或第三方服务商的评价（统称“反馈”），Taoify 均无义务进行保密或向您提供任何补偿。通过向 Taoify 提供反馈（无论是直接提交给 Taoify 还是任何 Taoify 主办的论坛或拥有的页面上发布），即表示您放弃对反馈内容所有的权利，并且 Taoify 有权可以在未经您或任何第三方的许可的情况下根据需要自由实施和使用您所提供的或经 Taoify 修改的反馈。您提交给 Taoify 的针对第三方服务或第三方服务商的任何评论必须是您所知最准确的，并且不得是违法的，淫秽的，威胁的，诽谤的，侵犯隐私的，侵犯知识产权的，或以其他方式伤害第三方或令人反感的。Taoify 保留删除、编辑针对第三方服务或第三方服务商反馈的权利（但没有这样做的义务），Taoify 没有定期检查已发布反馈的义务。") }}
</p>

<h3 class="titlee">{{ $t("十八.第三方权利") }}</h3>
<p class="content">
  {{ $t("除 Taoify 及其关联公司、Taoify 用户或根据本服务条款访问 Taoify 服务的任何人外，除非本服务条款中另有规定，非本服务条款当事方的任何个人或主体均无权执行本服务条款的任何条款。无论此人员或实体是否按名称已被确认为是某一群体类别的成员或者是符合某一特定描述的特征。为免生歧义，这不会影响这些条款的任何允许的受让人或受让人的权利。") }}
</p>

<h3 class="titlee">{{ $t("十九.隐私和数据保护") }}</h3>
<p class="content">
  {{ $t("Taoify 非常重视对您和您客户的个人信息的隐私保护（“个人信息”）。通过使用 Taoify 服务，您认可并同意 Taoify 在收集、使用和披露这些个人信息时遵守《隐私策略》的约定。此外，如果：(a) 您在欧洲经济区 (EEA) 中开展业务；(b) 您向欧洲经济区中的客户提供商品或服务；或者 (c) 您必须遵守欧盟通用数据保护条例的要求，则 Taoify 收集和使用任何欧洲居民的个人信息需要受我们的 《数据处理附录》约束。") }}
</p>
          <p class="content">
            © Taoify 2024 
          </p>
        </v-container>
      </v-card>
    </v-app>
  </template>
  
  <script>
  export default {};
  </script>
  
  <style lang="scss" scoped>
  .titlee {
    font-size: 18px;
    font-weight: 600;
    color: #333;
    line-height: 25px;
    margin-top: 25px;
    margin-bottom: 10px;
  }
  .content {
    font-size: 16px;
    line-height: 30px;
    font-weight: 300;
    color: #6a6e79;
  }
  li {
    font-size: 16px;
    line-height: 30px;
    font-weight: 300;
    color: #6a6e79;
    margin-left: 20px;
    list-style: initial;
  }
  </style>